'use client';

import { useLocale } from 'next-intl';
import EmbedHubspotForm from '../EmbedHubspotForm';

const FORMS_IDS = {
    ua: '4684e637-ca00-4698-8fff-34c79ca9a9d0',
    en: 'c0ec9ba7-bd26-4342-b889-f3504895323a',
    mx: 'e7e67712-dad4-46a1-b7e6-29dbf865138c',
    ru: '39c99bf1-3c9e-400f-82ba-4728450b21ed',
    'ru-kz': '39c99bf1-3c9e-400f-82ba-4728450b21ed',
};

export default function BlogSubscribeForm() {
    const lang = useLocale();

    return (
        <div className="bg-light-green rounded">
            <div className="row">
                <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8">
                    <EmbedHubspotForm formId={FORMS_IDS[lang]} layout=""/>
                </div>
            </div>
        </div>
    );
}
