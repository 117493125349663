import { pipe, curry, prop, uniq, sortBy, filter, map } from 'ramda';

const isCountryIncluded = (country, countries) => countries.includes(country) || countries.includes('*');

export const filterByCountry = curry((country, list) => list.filter(
    (item) => !item.countries || isCountryIncluded(country, item.countries),
));

const enrichPlansWithPrice = curry((country, plans) => plans.map((plan) => {
    const price = filterByCountry(country, plan.prices)[0];

    return {
        ...plan,
        price,
    };
}));

const valueDiffers = curry((plan1, plan2, feature) => plan1[feature.name] !== plan2[feature.name]);
const sortAddonsFirst = sortBy((f) => (f.value === 'Add-on' ? 1 : 0));
const addFeatureValueForPlan = curry((plan, feature) => ({ ...feature, value: plan.features[feature.name] }));
const parseFeatureImportantForCountry = curry((country, feature) => ({
    ...feature,
    important: feature.important && isCountryIncluded(country, feature.important),
}));

const enrichPlansWithFeaturesDiff = curry((features, country, plans) => plans.reduce((acc, plan) => {
    const previousPlan = acc[acc.length - 1];
    const previousPlanFeatures = previousPlan?.features || {};

    const diff = pipe(
        filterByCountry(country),
        filter(valueDiffers(plan.features, previousPlanFeatures)),
        map(addFeatureValueForPlan(plan)),
        map(parseFeatureImportantForCountry(country)),
        sortAddonsFirst,
    )(features);

    return [...acc, {
        ...plan,
        diff,
        previousPlanTitle: previousPlan?.title,
    }];
}, []));

export const getPricingPlansForCountry = (pricingConfig, countryCode) => pipe(
    filterByCountry(countryCode),
    enrichPlansWithPrice(countryCode),
    enrichPlansWithFeaturesDiff(pricingConfig.features, countryCode),
)(pricingConfig.plans);

const getFeaturesGroupedByModule = (features) => {
    const featuresWithModules = features.map((feature) => {
        const [module] = feature.name.split('.');

        return {
            ...feature,
            module,
        };
    });

    const modules = uniq(featuresWithModules.map(prop('module')));
    const groupedFeatures = modules.map((module) => ({
        module,
        features: featuresWithModules.filter((feature) => feature.module === module),
    }));

    return groupedFeatures;
};

export const getFeaturesGroupedByModuleForCountry = (features, countryCode) => pipe(
    filterByCountry(countryCode),
    getFeaturesGroupedByModule,
)(features);
