'use client';

import { Component, createRef } from 'react';

import Button from '../../UI/Button';
import { ModalDialog } from '../../UI/Modal';
import withTranslation from '../../../hoc/Intl';
import { isAccountExists } from '../../../helpers/apiClient';

class LoginModalFormAccount extends Component {
    static validateForm = async (url) => {
        let error = false;

        if (!url) {
            error = {
                type: 'invalid url',
                message: 'modal.login.errorSubdomainRequired',
            };
        } else if (!url.match(/^[0-9a-zA-Z\\-]{2,}$/)) {
            error = {
                type: 'invalid symbols',
                message: 'modal.login.errorSubdomainContainsInvalidCharacters',
            };
        } else if (!(await isAccountExists(url))) {
            error = {
                type: 'invalid url',
                message: 'modal.login.errorAccountNotFound',
            };
        }

        return error;
    };

    state = {
        inputError: false,
        busy: false,
    };

    inputRef = createRef();

    formRef = createRef();

    submitHandler = async (e) => {
        e.preventDefault();

        if (this.state.busy) {
            return;
        }

        this.setState({ busy: true });

        const url = this.inputRef.current.value;
        const inputError = await this.constructor.validateForm(url);

        if (!inputError) {
            // WEiRD: форма сабмітиться порожня, якщо не винести в setTimeout()
            // розберемося пізніше, якась проблема з await validateForm
            window.setTimeout(() => this.formRef.current.submit(), 1);
        }

        this.setState({ busy: false, inputError });
    };

    remindAccountNameHandler = () => {
        this.props.onRemindAccountName();
    };

    render() {
        const { t } = this.props.i18n;
        const { busy, inputError } = this.state;

        return (
            <ModalDialog
                title={t('modal.login.title')}
                footer={(
                    <>
                        <Button link onClick={this.remindAccountNameHandler}>
                            <span className="d-none d-sm-block"> {t('modal.login.forgotEmailLink')}</span>
                            <span className="d-sm-none"> {t('modal.login.forgotEmailLinkShort')}</span>
                        </Button>
                        <Button onClick={this.submitHandler} inProgress={busy}>{t('modal.login.submit')}</Button>
                    </>
                )}>
                <form
                    ref={this.formRef} action="/welcome/goto_url" method="POST" onSubmit={this.submitHandler}
                    noValidate
                >
                    <label className="form-label" htmlFor="url">{t('modal.login.inputLabel')}</label>
                    <div className={`input-group ${inputError ? 'has-validation' : ''}`}>
                        <input
                            id="url"
                            type="text"
                            name="url"
                            ref={this.inputRef}
                            required
                            disabled={busy}
                            className={`form-control ${inputError ? 'is-invalid' : ''}`}
                        />
                        <span className="input-group-text">.joinposter.com</span>
                        {inputError && <div className="invalid-feedback">{t(inputError?.message)}</div>}
                    </div>
                </form>
            </ModalDialog>
        );
    }
}

export default withTranslation(LoginModalFormAccount, 'content/login');
