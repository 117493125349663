'use client';

import BootstrapTooltip from 'components/bootstrap/tooltip';
import { useTranslations } from 'next-intl';
import Price from '../Price';
import useCountry from '../../../hooks/useCountry';
import { getFeaturesGroupedByModuleForCountry, getPricingPlansForCountry } from './pricingHelper';
import FeatureValue from './FeatureValue';
import FeatureTooltip from './FeatureTooltip';
import getPricingConfig from './pricing-config';

const ComparePricingPlans = ({ product }) => {
    const t = useTranslations('content/pricing');

    const config = getPricingConfig(product);
    const country = useCountry();
    const plans = getPricingPlansForCountry(config, country);
    const featuresGroupedByModule = getFeaturesGroupedByModuleForCountry(config.features, country);

    return (
        <div className="fs-6 fs-md-5">
            <BootstrapTooltip />

            <div className="list-group list-group-flush sticky-top py-3 bg-white border-bottom" style={{
                top: 70,
            }}>
                <div className="list-group-item px-0 px-md-3">
                    <div className="row">
                        <div className="col-4"/>

                        {plans.map((plan) => (
                            <div key={plan.title} className="col p-0 text-center">
                                <div className="fw-bold fs-6 fs-md-4">
                                    {plan.title}
                                </div>

                                <div className="d-none d-md-block text-secondary small">
                                    {t('startingWith')} <Price className="text-dark">{plan.price.annual}</Price>{t('/month')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            {featuresGroupedByModule.map(({ module, features }) => (
                <div className="list-group list-group-flush mb-5" key={module}>
                    <div className="list-group-item px-0 px-md-3 py-2 bg-light">
                        <span className="fw-bold">{t(`module.${module}`)}</span>
                    </div>

                    {features.map((feature) => (
                        <div
                            className="list-group-item list-group-item-action px-0 px-md-3 py-2"
                            key={feature.name}
                        >
                            <div className="row">
                                <div className="col-4">
                                    {t(`feature.${feature.name}`)}

                                    <FeatureTooltip feature={feature} className="ms-2" />
                                </div>

                                {plans.map((plan) => {
                                    const value = plan.features[feature.name];

                                    return (
                                        <div key={plan.title} className="col text-center">
                                            <FeatureValue value={value} />
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default ComparePricingPlans;
