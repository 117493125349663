import Script from 'next/script';

function LayoutComponent({ children, layout }) {
    if (layout === 'center') {
        return (
            <div className="row">
                <div className="offset-md-3 col-md-6">{children}</div>
            </div>
        );
    }

    if (layout === 'hero') {
        return (
            <div style={{ margin: '-40px 0 0 -40px' }}>{children}</div>
        );
    }

    return <>{children}</>;
}

export default function EmbedHubspotForm({ formId, layout = 'center' }) {
    const hubspotDiv = `<div class="hs-form-frame" data-region="eu1" data-form-id="${formId}" data-portal-id="145006115"></div>`;

    return (
        <LayoutComponent layout={layout}>
            <Script src="https://js-eu1.hsforms.net/forms/embed/145006115.js" defer/>
            <div id={formId} dangerouslySetInnerHTML={{ __html: hubspotDiv }}/>
        </LayoutComponent>
    );
}
