import IconInline from 'components/icons/IconInline';
import { useTranslations } from 'next-intl';

const FeatureValue = ({ value }) => {
    const t = useTranslations('content/pricing');

    const values = {
        true: <span className="text-success fs-5 fs-md-4"><IconInline name="circle-check" /></span>,
        'Add-on': (<span
            className="text-success fs-5 fs-md-4"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-bs-title={t('addonTooltip')}
        >
            <IconInline name="circle-plus" />
        </span>),
        false: '',
    };

    return values[value] || <span>{value}</span>;
};

export default FeatureValue;
