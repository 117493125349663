'use client';

import Script from 'next/script';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import sbjs from 'sourcebuster';

import RollbarResource from './RollbarResource';
import PreloadResources from './PreloadResources';
import { isProduction } from '../helpers/environment';
import { trackPageView } from '../helpers/analytics';

export default function CustomScripts({ abTestName, abTestVariant }) {
    const pathname = usePathname();

    // Поскольку next ведется себя как SPA, нужно сказать что меняется страница
    const onRouteChange = (url) => trackPageView(url);

    useEffect(() => {
        window.hsConversationsSettings = { loadImmediately: true };

        sbjs.init({
            domain: 'joinposter.com',
            isolate: true,
        });

        if (abTestName && abTestVariant) {
            // window.dataLayer = window.dataLayer || [];
            // window.dataLayer.push({
            //     experimentName: abTestName,
            //     experimentID: abTestVariant,
            // });
        }
    }, []);

    useEffect(() => onRouteChange(pathname), [pathname]);

    return (
        <>
            <PreloadResources/>

            {isProduction() && (
                <>
                    {/* GTM */}
                    <noscript>
                        <iframe
                            src="https://www.googletagmanager.com/ns.html?id=GTM-TG4MTPS"
                            height="0"
                            width="0"
                            style={{ display: 'none', visibility: 'hidden' }}
                        />
                    </noscript>
                    <Script
                        id="gtm-script"
                        strategy="lazyOnload"
                        async
                        dangerouslySetInnerHTML={{
                            __html: `
                        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                        })(window,document,'script','dataLayer','GTM-TG4MTPS');
                    `,
                        }}
                    />
                </>
            )}

            {isProduction() && (
                <Script async defer src="https://js-eu1.hs-scripts.com/145006115.js" id="hs-script-loader"/>
            )}

            {isProduction() && <RollbarResource/>}
        </>
    );
}
