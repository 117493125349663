'use client';

import { Component, createRef } from 'react';
import ReCAPTCHA from 'components/form/ReCAPTCHA';

import LoginModalMessage from './LoginModalMessage';
import Button from '../../UI/Button';
import { ModalDialog } from '../../UI/Modal';
import { RESPONSE_SUCCESS } from './LoginModalConstants';
import withTranslation from '../../../hoc/Intl';
import { sendDomainReminderEmail } from '../../../helpers/apiClient';
import { isValidEmail } from '../../../helpers/emails';

const VIEW_FORM = 'form';
const VIEW_RESULT = 'result';

class LoginModalFormEmail extends Component {
    state = {
        currentView: VIEW_FORM,
        inputError: false,
        busy: false,
        responseCode: null,
    };

    inputRef = createRef();

    recaptchaRef = createRef();

    submitHandler = async (e) => {
        e.preventDefault();

        if (this.state.busy) {
            return;
        }

        this.setState({ busy: true });

        const email = this.inputRef.current.value;
        const inputError = !isValidEmail(email);

        let responseCode;
        if (!inputError) {
            // reCaptcha token
            const token = await this.recaptchaRef.current.executeAsync();

            responseCode = await sendDomainReminderEmail(email, token);

            this.setState({
                responseCode,
                currentView: VIEW_RESULT,
            });
        }

        this.setState({ busy: false, inputError });
    };

    backHandler = () => {
        if (this.state.responseCode === RESPONSE_SUCCESS) {
            this.props.onGoBack();
        } else {
            this.setState({ currentView: VIEW_FORM });
        }
    };

    render() {
        const { currentView, responseCode, busy, inputError } = this.state;
        const { t } = this.props.i18n;

        return (
            <>
                {
                    currentView === VIEW_RESULT
                    && <LoginModalMessage responseCode={responseCode} onButtonClick={this.backHandler}/>
                }

                {
                    currentView === VIEW_FORM
                    && <ModalDialog
                        title={t('modal.login.remindTitle')}
                        footer={(
                            <>
                                <Button link onClick={this.props.onGoBack}>{t('modal.login.remindGoBack')}</Button>
                                <Button onClick={this.submitHandler}
                                    inProgress={busy}>{t('modal.login.remindSubmit')}</Button>
                            </>
                        )}
                    >
                        <form noValidate onSubmit={this.submitHandler}>
                            <p className="text-muted">
                                {t('modal.login.remindDescription')}
                            </p>
                            <label className="form-label"
                                htmlFor="login_email">{t('modal.login.remindInputLabel')}</label>
                            <div className={`input-group ${inputError ? 'has-validation' : ''}`}>
                                <input
                                    id="login_email"
                                    type="email"
                                    ref={this.inputRef}
                                    className={`form-control ${inputError ? 'is-invalid' : ''}`}
                                    placeholder="email@domain.com"
                                    required
                                    disabled={busy}
                                />
                                {inputError && (
                                    <div className="invalid-feedback">{t('modal.login.errorEmptyEmail')}</div>
                                )}
                            </div>
                            <ReCAPTCHA ref={this.recaptchaRef}/>
                        </form>
                    </ModalDialog>
                }
            </>
        );
    }
}

export default withTranslation(LoginModalFormEmail, 'content/login');
