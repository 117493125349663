import pricingPosterCafe from './PricingPosterCafe.config.json';
import pricingPosterRetail from './PricingPosterRetail.config.json';

const PRICING_CONFIGS = {
    PosterCafe: pricingPosterCafe,
    PosterRetail: pricingPosterRetail,
};

const getPricingConfig = (product) => PRICING_CONFIGS[product];

export default getPricingConfig;
